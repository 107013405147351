import { LinkActionTypeCms } from '@/types/cms';
import {
  AdPlacementsProps,
  ChannelGroups,
  ContentFeedEntryEntryType,
  EventPageSlug,
  LinkActionType,
  PageTheme,
  TaggingToolContentType,
  TeaserGroup,
} from '@/types/views/generic';
import { Section, WatchOverviewItemsType } from '@/types/views/sections';

export const SPONSORED_CONTENT_TAG = '0osOvZQWRXFpYERSNypUK';

export const AVAILABLE_PAGE_THEMES: PageTheme[] = [
  'dark',
  'light',
  'eone',
  'eair',
  'esn',
  'eds',
  'els',
  'game',
  'legal',
];

export const EVENT_THEMES: PageTheme[] = ['eair', 'esn', 'eds', 'els'];

export const DEFAULT_CHANNEL_ID = 'zuerich';

export const LOCAL_STORAGE_KEYS = {
  currentChannelId: 'currentChannelId',
  volume: 'volume',
  isMuted: 'isMuted',
  csFpid: 'csFpid',
};

export const CHANNEL_GROUPS_EMPTY: ChannelGroups = {
  station: [],
  music: [],
  external: [],
};

export const TAGGING_TOOL_CONTENT_TYPES: Record<
  TaggingToolContentType,
  boolean
> = {
  video: true,
  meme: true,
  format: true,
  show: true,
  soundpiece: true,
};

export const WATCH_OVERVIEW_CONTENT_TYPES: Record<
  WatchOverviewItemsType,
  boolean
> = {
  video: true,
  meme: true,
};

export const pluralNameByContentType: Partial<
  Record<ContentFeedEntryEntryType, string>
> = {
  video: 'videos',
  meme: 'memes',
  soundpiece: 'soundpieces',
};

export const contentTypesByPluralName = Object.entries(
  pluralNameByContentType,
).reduce((acc, [contentType, pluralName]) => {
  acc[pluralName] = contentType as ContentFeedEntryEntryType;

  return acc;
}, {} as Partial<Record<string, ContentFeedEntryEntryType>>);

const textVariables = {
  allVideos: 'Alle Videos',
  allMemes: 'Alle Memes',
};

export const contentDataByType = {
  video: {
    href: '/watch/videos',
    teaserGroup: TeaserGroup.Videos,
    text: textVariables.allVideos,
    isExternal: false,
  },
  meme: {
    href: '/watch/memes',
    teaserGroup: TeaserGroup.Memes,
    text: textVariables.allMemes,
    isExternal: false,
  },
};

export const CONTENT_MEDIA_TYPE = {
  VIDEO_MP4: 'video/mp4',
};

export const COMSCORE_DEFAULT_LABELS: Omit<
  ComscoreLabelsObject,
  'cs_fpid' | 'mp_login' | 'mp_fpid'
> = {
  c1: '2',
  c2: '34949605',
  mp_brand: 'Energy Schweiz',
  ns_site: 'energy.ch',
  mp_v: null,
};

export const HOMEPAGE_URLS = [
  '/',
  '/star-night',
  '/air',
  '/die-sprechstunde-live',
  '/live-session',
];

export const FULL_WIDTH_SECTIONS: Record<
  PageTheme,
  Section['componentType'][]
> = {
  light: ['gallery'],
  dark: ['gallery'],
  eone: [],
  eair: [
    'hero',
    'heroLivestream',
    'dualHero',
    'teaser',
    'gallery',
    'lotteryHeader',
    'relatedArticles',
  ],
  esn: [
    'hero',
    'heroLivestream',
    'dualHero',
    'lotteryHeader',
    'pageHeader',
    'gallery',
  ],
  eds: ['hero', 'dualHero', 'gallery'],
  els: ['hero', 'dualHero', 'gallery'],
  game: [],
  legal: [],
  'music-award': ['hero', 'dualHero', 'gallery'],
};

export const NO_PADDING_SECTIONS: Section['componentType'][] = [
  'stationHeader',
  'pageHeader',
  'hero',
  'dualHero',
  'heroLivestream',
  'quiz',
  'lotteryHeader',
];

export const EVENT_PAGE_SLUGS: EventPageSlug[] = [
  'air',
  'star-night',
  'die-sprechstunde-live',
  'live-session',
];

export const AdPlacements: AdPlacementsProps = {
  Home: {
    Desktop: [
      'SBA_1',
      'SBA_2',
      'TA_1',
      'WB_1',
      'WB_2-MR',
      'WB_3',
      'MR_1',
      'MR_2',
      'MR_3',
    ],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
  Watch: {
    Desktop: [
      'SBA_1',
      'SBA_2',
      'TA_1',
      'WB_1',
      'WB_2-MR',
      'MR_1',
      'MR_2',
      'PREROLL_1',
    ],
    MobileWeb: ['MMR_1', 'MMR_2', 'MHPA_2', 'PREROLL_1', 'MTA_1'],
  },
  Events: {
    Desktop: ['SBA_1', 'SBA_2', 'TA_1', 'WB_1', 'WB_2-MR', 'WB_3', 'MR_1'],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
  Air: {
    Desktop: ['SBA_1', 'SBA_2', 'TA_1', 'WB_1', 'WB_2-MR', 'WB_3', 'MR_1'],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3'],
  },
  StarNight: {
    Desktop: ['SBA_1', 'SBA_2', 'TA_1', 'WB_1', 'WB_2-MR', 'WB_3', 'MR_1'],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
  DieSprechstunde: {
    Desktop: ['SBA_1', 'SBA_2', 'TA_1', 'WB_1', 'WB_2-MR', 'WB_3', 'MR_1'],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
  Channels: {
    Desktop: ['SBA_1', 'SBA_2', 'TA_1', 'WB_1', 'WB_2-MR', 'WB_3', 'MR_1'],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
  OnAir: {
    Desktop: ['SBA_1', 'SBA_2', 'WB_1', 'MR_1'],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
  Profiles: {
    Desktop: ['SBA_1', 'SBA_2', 'TA_1', 'WB_1', 'WB_2-MR', 'WB_3', 'MR_1'],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
  Game: {
    Desktop: [],
    MobileWeb: [],
  },
  RoS: {
    Desktop: [
      'SBA_1',
      'SBA_2',
      'TA_1',
      'WB_1',
      'WB_2-MR',
      'WB_3',
      'MR_1',
      'MR_2',
    ],
    MobileWeb: ['MMR_1', 'MHPA_2', 'MHPA_3', 'MTA_1'],
  },
};

export const EMAIL_REGEX_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

export const actionsTypeMap: Record<LinkActionTypeCms, LinkActionType> = {
  [LinkActionTypeCms.EditProfile]: LinkActionType.EditProfile,
  [LinkActionTypeCms.OpenNewsletter]: LinkActionType.OpenNewsletter,
};

export const MINIMUM_NUMBER_OF_VISIBLE_VIDEOS = 6;

export const DEFAULT_AD_PREROLL_RATE = 50;

export const QUESTION_ID_COOKIE = 'quiz_questionId';

export const ANSWER_ID_COOKIE = 'quiz_answerId';

export const GTAG_ID = 'GTM-PVM7KT';

export const ONETRUST_INITIALIZED_EVENT_TYPE = 'OT_initialized';

export const ONETRUST_CONSENT_CHANGED_EVENT_TYPE = 'OT_consentChanged';

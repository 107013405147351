import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPlayerChannelGroups, setVolume } from '@/store/slices/player';

import { LOCAL_STORAGE_KEYS } from '../constants';

const useInitStore = () => {
  const dispatch = useDispatch();
  const channelGroups = useSelector(selectPlayerChannelGroups);

  useEffect(() => {
    const volumeInLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_KEYS.volume,
    );

    if (volumeInLocalStorage !== null) {
      dispatch(setVolume(JSON.parse(volumeInLocalStorage)));
    }
  }, [dispatch, channelGroups]);
};

export default useInitStore;

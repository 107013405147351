import { Entry } from 'contentful';
import { format } from 'date-fns';
import { ImageLoader } from 'next/image';
import * as R from 'ramda';
import { useEffect, useLayoutEffect } from 'react';

import {
  ImageAsset,
  ItemsCollection,
  MediaAsset,
  VideoAsset,
} from '@/types/views/generic';

// eslint-disable-next-line import/prefer-default-export
export const notNull = <TValue>(value: TValue | null): value is TValue =>
  value !== null;

export const customLoader: ImageLoader = ({ src, quality = 75, width }) =>
  `${src}?w=${width}&q=${quality}&fm=webp`;

/**
 * Gets rid of useLayoutEffect in SSR warning
 * @see https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const getEntryContentTypeId = (entry: Entry<unknown>) =>
  entry.sys.contentType.sys.id;

export const isBrowser = () => typeof window !== 'undefined';

export const isNotNil = (element: unknown) => !R.isNil(element);

export const padTimeToTwo = (time: number) => time.toString().padStart(2, '0');

export const isVideoAsset = (value: MediaAsset): value is VideoAsset =>
  value.contentType === 'video';

export const isImageAsset = (value: MediaAsset): value is ImageAsset =>
  value.contentType === 'image';

export const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const isEnvVarTrue = (stringBool: string | null | undefined): boolean =>
  stringBool?.trim().toLowerCase() === 'true';

/**
 * Find first parent recursively by property value that satisfies the criteria
 */
export const findParentElement = (
  child: HTMLElement,
  propertyName: keyof HTMLElement,
  value: unknown,
): HTMLElement | null => {
  const parent = child.parentElement;

  return parent === null || parent?.[propertyName] === value
    ? parent
    : findParentElement(parent, propertyName, value);
};

export const formatDate = (date: string, dateFormat = 'dd.MM.yyyy hh:mm') => {
  const currentDate = new Date(date);

  return format(currentDate, dateFormat);
};

/**
 * @param pageIndex - only 1...Infinity integers
 */
export const watchVideosMemeDateRange = (pageIndex: number) => {
  const endDate = new Date();
  const startDateMap: Record<number, number> = {
    1: 2,
    2: 6,
    3: 12,
  };

  if (!Object.keys(startDateMap).includes(pageIndex.toString())) {
    return {
      startDate: null,
      endDate: null,
    };
  }

  const formatDateRange = (date: Parameters<typeof format>[0]) =>
    format(date, 'yyyy-MM-dd');

  const startDate = new Date(endDate).setMonth(
    endDate.getMonth() - (startDateMap[pageIndex] ?? 1),
  );

  return {
    startDate: formatDateRange(startDate),
    endDate: formatDateRange(endDate),
  };
};

/**
 * @param pageIndex - only 1...Infinity integers
 */
export const audiosDateRange = (pageIndex: number) => {
  const endDate = new Date();
  const startDateMap: Record<number, number> = {
    0: 0.5,
    1: 1,
    2: 6,
    3: 12,
  };

  if (!Object.keys(startDateMap).includes(pageIndex.toString())) {
    return {
      startDate: null,
      endDate: null,
    };
  }

  const formatDateRange = (date: Parameters<typeof format>[0]) =>
    format(date, 'yyyy-MM-dd');

  const startDate =
    startDateMap[pageIndex] === 0.5
      ? new Date(endDate).setDate(endDate.getDate() - 7)
      : new Date(endDate).setMonth(
          endDate.getMonth() - (startDateMap[pageIndex] ?? 1),
        );

  return {
    startDate: formatDateRange(startDate),
    endDate: formatDateRange(endDate),
  };
};

export const isEntryPublished = (entry: { published_at: string | null }) => {
  // TODO: make sure if return true when published_at is null
  if (entry.published_at === null) return true;

  return new Date() > new Date(entry.published_at);
};

export const capitalize = (str: string) =>
  str.replace(str[0], str[0].toUpperCase());

/**
 * mapToSlug('Cinderella 2020')
 * @example
 * // returns 'cinderella-2020'
 */
export const mapToSlug = (text: string): string =>
  text
    .toLowerCase()
    .replace(/[ä]/g, 'ae')
    .replace(/[ö]/g, 'oe')
    .replace(/[ü]/g, 'ue')
    .replace(/[^a-z0-9 ]/g, '')
    .trim()
    .replace(/\s+/g, '-');

export const debounce = (callback: TimerHandler, delay = 100) => {
  let timeoutId;

  clearTimeout(timeoutId);
  timeoutId = setTimeout(callback, delay);
};

export const getQueryParamValue = (paramKey: string): string | null => {
  if (!window) return null;

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const value = params.get(paramKey);

  return value || null;
};

export const getEmptyItemsCollection = <T>(): ItemsCollection<T> => ({
  items: [],
  totalCount: 0,
});

export const getRandomElement = <T extends unknown>(array: T[]): T =>
  array[Math.floor(Math.random() * array.length)];

/**
 * Get HH:mm:ss from seconds
 * @param seconds
 */
export const getTime = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds);

  return date.toISOString().substr(11, 8);
};

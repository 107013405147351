import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export type VideoState = {
  currentPlayingVideo: string | null;
};

export const initialState: VideoState = {
  currentPlayingVideo: null,
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setCurrentPlayingVideo: (
      state,
      action: PayloadAction<VideoState['currentPlayingVideo']>,
    ) => {
      state.currentPlayingVideo = action.payload;
    },
  },
});

export const { setCurrentPlayingVideo } = videoSlice.actions;

export const selectCurrentVideo = (state: RootState) => state.video;

export const selectCurrentPlayingVideo = (state: RootState) =>
  state.video.currentPlayingVideo;

export default videoSlice.reducer;

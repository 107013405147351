import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getQueryParamValue } from '@/lib/utils';
import { selectComscoreCsFpid } from '@/store/slices/global';
import { selectIsUserLoggedIn, selectUser } from '@/store/slices/user';

import { COMSCORE_DEFAULT_LABELS } from '../constants';

const getComscoreLabels = (
  comscoreCsFpid: string,
  comscoreFirstPartyIdHash: string | null,
  isUserLoggedIn: boolean,
): ComscoreLabelsObject => ({
  ...COMSCORE_DEFAULT_LABELS,
  cs_fpid: comscoreCsFpid,
  ...(isUserLoggedIn &&
    comscoreFirstPartyIdHash && { mp_fpid: comscoreFirstPartyIdHash }),
  /**
   * 1: Indicating if the user has a hashed login value transmitted as an identifier
   * 3: Indicating if the user has a random 1st party identifier transmitted
   */
  mp_login: isUserLoggedIn ? 1 : 3,
});

const sendComscoreLabels = (
  comscoreCsFpid: string | null,
  comscoreFirstPartyIdHash: string | null,
  isUserLoggedIn: boolean,
) => {
  if (
    comscoreCsFpid &&
    // if we are logged in we need to make sure we have a hash
    // if we are not, we don't have to have it
    (!isUserLoggedIn || (isUserLoggedIn && comscoreFirstPartyIdHash))
  ) {
    window?.COMSCORE?.beacon(
      getComscoreLabels(
        comscoreCsFpid,
        comscoreFirstPartyIdHash,
        isUserLoggedIn,
      ),
    );
  } else {
    // TODO: Log that to sentry!
    console.error('comscoreFirstPartyIds not set.');
  }
};

const CHECK_INTERVAL = 100;
const CHECK_TIMEOUT = 15 * 1000; // 15 seconds

const useTrackComscore = () => {
  const router = useRouter();
  const { comscoreFirstPartyIdHash, isDataInitialized: isUserDataInitialized } =
    useSelector(selectUser);
  const comscoreCsFpid = useSelector(selectComscoreCsFpid);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  useEffect(() => {
    const hasAppTag = getQueryParamValue('app') === 'true';

    const onBeforeHistoryChange = (url: string) => {
      if (url !== `${window.location.pathname}${window.location.search}`) {
        sendComscoreLabels(
          comscoreCsFpid,
          comscoreFirstPartyIdHash,
          isUserLoggedIn,
        );
      }
    };

    if (!hasAppTag) {
      router.events.on('beforeHistoryChange', onBeforeHistoryChange);
    }

    return () => {
      if (!hasAppTag) {
        router.events.off('beforeHistoryChange', onBeforeHistoryChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comscoreCsFpid, comscoreFirstPartyIdHash, isUserLoggedIn]);

  useEffect(() => {
    const hasAppTag = getQueryParamValue('app') === 'true';

    if (!hasAppTag && isUserDataInitialized) {
      const start = Date.now();

      /* 
        Wait for Comscore script to initialize
        Note: The script is initialized in _document.ts
          and therefore we cannot use Redux instead.
        TODO: Check sometime in the future if there's a better way. (Callback available?)
      */
      const interval = setInterval(() => {
        // Timeout after CHECK_TIMEOUT
        if (Date.now() - start > CHECK_TIMEOUT) {
          clearInterval(interval);

          return;
        }

        if (window.COMSCORE?.beacon) {
          sendComscoreLabels(
            comscoreCsFpid,
            comscoreFirstPartyIdHash,
            isUserLoggedIn,
          );
          clearInterval(interval);
        }
      }, CHECK_INTERVAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserDataInitialized]);
};

export default useTrackComscore;

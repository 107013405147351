import fetchWithCatch from '@/lib/fetch-with-catch';

interface RelativeUrl {
  pathname: string;
  search: string;
  hash: string;
}

const getRelativeUrl = (url: RelativeUrl) =>
  `${url.pathname}${url.search}${url.hash}`;

export const attachRedirectPage = (relativeUrl: string) => {
  const url = new URL(`https://example.com${relativeUrl}`);
  const redirectPage = getRelativeUrl(window.location);
  url.searchParams.append('redirect_page', redirectPage);

  return getRelativeUrl(url);
};

const isLoginDisabled = process.env.NEXT_PUBLIC_DISABLE_LOGIN === 'true';

export const login = () => {
  if (isLoginDisabled) {
    window.location.href = '/login_error';

    return;
  }
  window.location.href = attachRedirectPage('/api/authorize');
};

export const logout = () => {
  if (isLoginDisabled) {
    window.location.href = '/login_error';

    return;
  }
  window.location.href = attachRedirectPage('/api/logout');
};

export const fetchEditProfileUrl = async () => {
  const { data } = await fetchWithCatch<{ url: string }>(() =>
    fetch(`/api/edit-profile`, {
      method: 'GET',
    }),
  );

  return data?.url || '';
};

export const goToProfile = () => {
  const url = new URL(`${window.location.origin}/account`);
  const redirectPage = getRelativeUrl(window.location);
  url.searchParams.append('redirect_page', redirectPage);

  window.location.href = url.href;
};

export const goToProfileTickets = () => {
  const url = new URL(`${window.location.origin}/account/tickets`);
  const redirectPage = getRelativeUrl(window.location);
  url.searchParams.append('redirect_page', redirectPage);

  window.location.href = url.href;
};

export const register = () => {
  if (isLoginDisabled) {
    window.location.href = '/login_error';

    return;
  }
  window.location.href = attachRedirectPage(`/api/authorize#/register`);
};

import { AdSlot } from '@/types/views/generic';

export type AdSlotsConfigProps = Record<
  AdSlot,
  {
    container: string;
    width?: number | string;
    height?: number;
    minHeight?: number;
  }
>;

const AdSlotsConfig: AdSlotsConfigProps = {
  SBA_1: {
    container: 'ad-sba-slot-sba1',
    width: 300,
    height: 600,
  },
  SBA_2: {
    container: 'ad-sba-slot-sba2',
    width: 300,
    height: 600,
  },
  TA_1: {
    container: 'ad-sba-slot-ta1',
  },
  MTA_1: {
    container: 'ad-sba-slot-mta1',
  },
  WB_1: {
    container: 'ad-sba-slot-wb1',
    width: '100%',
    height: 250,
    minHeight: 50,
  },
  'WB_2-MR': {
    container: 'ad-sba-slot-wb2-mr',
    width: '100%',
    height: 250,
    minHeight: 50,
  },
  WB_3: {
    container: 'ad-sba-slot-wb3',
    width: '100%',
    height: 250,
    minHeight: 50,
  },
  MR_1: {
    container: 'ad-mr-slot-mr1',
    width: '100%',
    height: 250,
  },
  MR_2: {
    container: 'ad-mr-slot-mr2',
    width: '100%',
    height: 250,
  },
  MR_3: {
    container: 'ad-mr-slot-mr3',
    width: '100%',
    height: 250,
  },
  MMR_1: {
    container: 'ad-mr-slot-mmr1',
    width: '100%',
    height: 250,
    minHeight: 50,
  },
  MMR_2: {
    container: 'ad-mr-slot-mmr2',
    width: '100%',
    height: 300,
    minHeight: 50,
  },
  MHPA_2: {
    container: 'ad-mr-slot-mhpa2',
    width: '100%',
    minHeight: 150,
  },
  MHPA_3: {
    container: 'ad-mr-slot-mhpa3',
    width: '100%',
    minHeight: 100,
  },
  PREROLL_1: {
    container: 'ad-preroll1',
    width: 0,
    height: 0,
  },
};

export default AdSlotsConfig;

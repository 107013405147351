import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { fetchEditProfileUrl } from '@/lib/auth';
import { setIsNewsletterOpen } from '@/store/slices/global';
import { LinkActionType } from '@/types/views/generic';

const useHandleURLHashAction = () => {
  const { asPath, replace: routeReplace } = useRouter();
  const dispatch = useDispatch();

  const dispatchActionTypes: Record<LinkActionType, () => void> = useMemo(
    () => ({
      [LinkActionType.EditProfile]: async () => {
        const editProfileUrl = await fetchEditProfileUrl();
        window.location.href = editProfileUrl;
      },
      [LinkActionType.OpenNewsletter]: () =>
        dispatch(setIsNewsletterOpen(true)),
    }),
    [dispatch],
  );

  useEffect(() => {
    const hashParam = asPath.match(/#([a-zA-Z0-9]+)/gi);

    if (hashParam?.length) {
      const hashParamAction = hashParam[0];
      const action = hashParamAction.replace('#', '') as LinkActionType;

      if (dispatchActionTypes?.[action]) {
        dispatchActionTypes[action]();
      }
    }

    return () => undefined;
  }, [asPath, dispatchActionTypes, routeReplace]);
};

export default useHandleURLHashAction;

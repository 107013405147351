import crypto from 'crypto';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SessionResponse } from '@/api/types';
import { setComscoreCsFpid } from '@/store/slices/global';
import { setCurrentChannelId } from '@/store/slices/player';
import { getUserInit, setSessionData } from '@/store/slices/user';

import { DEFAULT_CHANNEL_ID, LOCAL_STORAGE_KEYS } from '../constants';
import fetchWithCatch from '../fetch-with-catch';

const useInitSession = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchSession = async () => {
      dispatch(getUserInit());

      // TODO create thunk action for session fetching
      const { data, error } = await fetchWithCatch<SessionResponse>(() =>
        fetch('/api/auth/session', {
          cache: 'no-cache',
          // @ts-ignore Fetch request ASAP
          priority: 'high',
        }),
      );

      const channelIdInLocalStorage = localStorage.getItem(
        LOCAL_STORAGE_KEYS.currentChannelId,
      );

      const fallbackChannelId = channelIdInLocalStorage
        ? JSON.parse(channelIdInLocalStorage)
        : DEFAULT_CHANNEL_ID;

      if (error || !data) {
        dispatch(
          setSessionData({
            profile: null,
            comscoreFirstPartyIdHash: null,
          }),
        );
        dispatch(setCurrentChannelId(fallbackChannelId));

        return;
      }

      const { currentChannelId, userProfile, comscoreFirstPartyIdHash } = data;

      dispatch(setCurrentChannelId(currentChannelId ?? fallbackChannelId));
      dispatch(
        setSessionData({
          profile: userProfile,
          comscoreFirstPartyIdHash,
        }),
      );
    };

    const comscoreCsFpid =
      localStorage.getItem(LOCAL_STORAGE_KEYS.csFpid) ||
      crypto.randomBytes(20).toString('hex');

    localStorage.setItem(LOCAL_STORAGE_KEYS.csFpid, comscoreCsFpid);
    dispatch(setComscoreCsFpid(comscoreCsFpid));

    fetchSession();
  }, [dispatch]);
};

export default useInitSession;

import { useEffect, useState } from 'react';

import { screens as breakpoints } from '@/../tailwind/theme';

import { debounce } from '../utils';

const getNumber = (value: string): number => {
  const foundNumber = value.match(/([0-9.]*)/);

  if (foundNumber === null) {
    throw new Error(`No number found in '${value}' string.`);
  }

  return parseFloat(foundNumber[0]);
};

const useBreakpoint = () => {
  const [screenWidth, setScreenWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );
  const [screenHeight, setScreenHeight] = useState<number>(
    typeof window !== 'undefined' ? window.innerHeight : 0,
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const callback = () =>
        debounce(() => {
          setScreenWidth(window.innerWidth);
          setScreenHeight(window.innerHeight);
        });

      window.addEventListener('resize', callback);

      return () => {
        window.removeEventListener('resize', callback);
      };
    }

    return undefined;
  }, []);

  const isMobile = screenWidth < getNumber(breakpoints.md);

  const isPortrait = screenHeight > screenWidth;

  const currentBreakpoint =
    Object.entries(breakpoints)
      .reverse()
      .find(([_, value]) => screenWidth >= getNumber(value))?.[0] ?? 'sm';

  return {
    screenWidth,
    currentBreakpoint,
    breakpoints,
    isMobile,
    isPortrait,
  };
};

export default useBreakpoint;

import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';

const useProgressBar = () => {
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = () => NProgress.start();
    const onRouteChangeComplete = () => NProgress.done();

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    router.events.on('routeChangeError', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.events.off('routeChangeError', onRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useProgressBar;

/* eslint-disable @typescript-eslint/no-explicit-any */
import cls from 'classnames';
import getConfig from 'next/config';
import * as R from 'ramda';
import { forwardRef } from 'react';

import { AdPlacements, EVENT_PAGE_SLUGS } from '@/lib/constants';
import { findParentElement, isEnvVarTrue, notNull } from '@/lib/utils';
import { AdChannel, AdSlot } from '@/types/views/generic';

import AdSlotsConfig from './ad-slots';

const getSlotKey = (adContainerId: string) => {
  const configEntry = Object.entries(AdSlotsConfig).find(
    ([, value]) => value.container === adContainerId,
  );

  return configEntry ? (configEntry[0] as AdSlot) : null;
};

export const clearAds = () => {
  const adIds = Object.values(AdSlotsConfig).map(R.prop('container'));

  adIds.forEach((id) => {
    // Do not clear WelcomeAd TA_1 and MTA_1 which would otherwise lead to empty containers
    if (id === 'ad-sba-slot-ta1' || id === 'ad-sba-slot-mta1') {
      return;
    }
    const element = document.querySelector(`[data-slotid="${id}"]`);

    if (element) {
      element.innerHTML = '';
    }
  });

  // TODO: Probably call window.top.admTagMan.pApi.resetAll here?
};

const hideWatchExperienceAdContainer = (elementId: string) => {
  const adElement = document.getElementById(elementId);

  if (adElement) {
    const parentSection = findParentElement(adElement, 'tagName', 'ASIDE');

    if (parentSection) {
      parentSection.style.display = 'none';
    }
  }
};

const showAndAdjustContainerSize =
  (eventName: string, elementId: string) => (data: any) => {
    const adElement = document.getElementById(elementId);

    // TODO: Kept for debugging reasons; to be removed in future
    // eslint-disable-next-line no-console
    console.log(eventName, { data, adElement });
  };

const registerAds = (
  adContainers: Element[],
  channel: AdChannel,
  adsDevice: string,
  currentBreakpoint: string,
  dynamicAdPosition = false,
  onAdReady?: AdmTagMan['onEvent'],
  onAdEmpty?: AdmTagMan['onEvent'],
  onAdError?: AdmTagMan['onEvent'],
) => {
  if (!window.admTagMan) return;

  const posIndex = {
    'WB_2-MR': 1,
    MHPA_2: 1,
  };

  window.admTagMan.q.push(() => {
    adContainers.forEach((adContainer) => {
      const slotId = adContainer.getAttribute('data-slotid');
      const slotKey = getSlotKey(slotId || adContainer.id);

      // Remove adm-* classes and possible old ads
      // before (re-)registering (recommended by RIAD)
      adContainer.classList.remove('adm-empty', 'adm-loaded', 'adm-error');
      // eslint-disable-next-line no-param-reassign
      adContainer.innerHTML = '';

      if (slotKey && AdPlacements[channel][adsDevice]?.includes(slotKey)) {
        // Do not register left sidebar if not in view
        if (
          !['2xl', '3xl'].includes(currentBreakpoint) &&
          slotKey === 'SBA_2'
        ) {
          return;
        }

        const splitCurrentAdIndex = adContainer.id.split('-');
        const currentAdIndex = R.last(splitCurrentAdIndex);

        window.admTagMan.registerSlot({
          slot: slotKey,
          container: adContainer.id,
          events: {
            // Must be done twice because adReady data is sometimes insufficient
            adReady: (...args: unknown[]) => {
              showAndAdjustContainerSize('adReady', adContainer.id)(args[0]);
              onAdReady && onAdReady(...args);
            },
            adEmpty: (...args: unknown[]) => {
              if (channel === 'Watch') {
                hideWatchExperienceAdContainer(adContainer.id);
              }
              onAdEmpty && onAdEmpty(...args, adContainer.id);
            },
            adError: (...args: unknown[]) => {
              if (channel === 'Watch') {
                hideWatchExperienceAdContainer(adContainer.id);
              }
              onAdError && onAdError(...args);
            },
            adLoaded: showAndAdjustContainerSize('adLoaded', adContainer.id),
          },
          targeting: {
            ...(!dynamicAdPosition && posIndex[slotKey as 'WB_2-MR' | 'MHPA_2']
              ? // eslint-disable-next-line no-plusplus
                {
                  pos: (posIndex[slotKey as 'WB_2-MR' | 'MHPA_2']++).toString(),
                }
              : {}),
            ...(dynamicAdPosition && { pos: currentAdIndex }),
          },
        });
      }
    });
  });

  window.admTagMan.q.push(() => {
    window.admTagMan.loadSlots();
  });
};

export const registerAd = (
  container: Element,
  channel: AdChannel,
  isMobile: boolean,
  currentBreakpoint: string,
  onAdReady?: AdmTagMan['onEvent'],
  onAdEmpty?: AdmTagMan['onEvent'],
  onAdError?: AdmTagMan['onEvent'],
) => {
  const adsDevice = isMobile ? 'MobileWeb' : 'Desktop';

  registerAds(
    [container],
    channel,
    adsDevice,
    currentBreakpoint,
    true,
    onAdReady,
    onAdEmpty,
    onAdError,
  );
};

export const updateAds = (
  channel: AdChannel,
  isMobile: boolean,
  currentBreakpoint: string,
  adSlotsToUpdate?: AdSlot[],
  additionalEntriesAmount?: number,
) => {
  const adsDevice = isMobile ? 'MobileWeb' : 'Desktop';
  const adContainerElements = document.querySelectorAll(
    '.ad-container:not(.adm-loaded)',
  );
  const filterAdSlotsToUpdate = (adContainer: Element) => {
    const slotAttributeId = adContainer.getAttribute('data-slotid');

    return adSlotsToUpdate?.some(
      (adSlot) => AdSlotsConfig[adSlot].container === slotAttributeId,
    );
  };

  const adContainers = adSlotsToUpdate?.length
    ? Array.from(adContainerElements).filter(filterAdSlotsToUpdate)
    : Array.from(adContainerElements);

  const lastNAdContainers = adContainers.slice(
    additionalEntriesAmount ? -additionalEntriesAmount : 0,
  );

  registerAds(lastNAdContainers, channel, adsDevice, currentBreakpoint, true);
};

const findAndRegisterAds = (
  channel: AdChannel,
  currentBreakpoint: string,
  isCreatorProfilePage: boolean,
  adsDevice: 'MobileWeb' | 'Desktop',
  skippedSlots: AdSlot[] = [],
) => {
  // NOTE: Show NO ads on profile pages that are not creators
  if (channel === 'Profiles' && !isCreatorProfilePage) {
    return;
  }

  // Register slots
  const adContainerElements = document.getElementsByClassName('ad-container');

  const adContainers = Array.from(adContainerElements).filter((container) => {
    const slotId = container.getAttribute('data-slotid');
    const slotKey = getSlotKey(slotId || container.id);

    return slotKey && !skippedSlots.includes(slotKey);
  });

  registerAds(adContainers, channel, adsDevice, currentBreakpoint);
};

export const initAds = (
  channel: AdChannel,
  isMobile: boolean,
  currentBreakpoint: string,
  isCreatorProfilePage: boolean,
  isUserLoggedIn?: boolean,
  afterInit?: () => void,
) => {
  if (!window.admTagMan) return;
  const { publicRuntimeConfig } = getConfig();
  const adsDevice = isMobile ? 'MobileWeb' : 'Desktop';
  const path = window.location.pathname;
  const targetingChannel = path
    .replace('/', '')
    .replace(
      /^(\w)(.+)/,
      (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase(),
    );
  // Magic number 9 in substring is length of '/artikel/
  const articleId = path.substring(path.lastIndexOf('/artikel/') + 9);

  // Clean ads containers on re-register
  clearAds();

  // Init channel
  window.admTagMan.q.push(() => {
    window.admTagMan.init({
      platform: adsDevice,
      channel,
      targeting: {
        admforce: isEnvVarTrue(publicRuntimeConfig.adsQAMode)
          ? 'qa'
          : undefined,
        olid: isUserLoggedIn ? 2 : 0,
        ...(targetingChannel === 'Schlaumeier' && {
          channel: targetingChannel,
        }),
        ...(path.includes('/artikel/') && {
          articleid: articleId,
        }),
      },
    });
  });

  afterInit && afterInit();

  findAndRegisterAds(
    channel,
    currentBreakpoint,
    isCreatorProfilePage,
    adsDevice,
  );
};

export const mountAdsHelperFunctionsOnWindow = () => {
  // @ts-ignore 'Ads' prop check
  if (window && typeof window?.Ads === 'undefined') {
    const getSidebar = (position: 'left' | 'right' = 'right') =>
      document.getElementById(`ads-papi-sidebar-${position}`);

    const alterStyle = (
      props: (keyof HTMLElement['style'])[],
      value: string,
    ) => {
      ['right', 'left']
        .map((side) => getSidebar(side as 'right' | 'left'))
        .filter(notNull)
        .forEach((element) => {
          props.forEach((prop) => {
            // @ts-ignore Only writable available
            // eslint-disable-next-line no-param-reassign
            element.style[prop] = value;
          });
        });
    };

    const setSideBar = () => {
      alterStyle(['width', 'height'] as (keyof CSSStyleDeclaration)[], '100%');
    };

    const resetAll = () => {
      alterStyle(['width', 'height'] as (keyof CSSStyleDeclaration)[], '');
    };

    /**
     * WelcomeAd functions which can be called from RiAd.
     * It can be used to implement our own welcomeAd behavior.
     * These functions can be executed within the browser console: e.g. window.top.Ads.setWelcomeAd()
     * Keeping it as might be useful someday.
     */

    const setWelcomeAd = () => {
      // window.top.Ads.helpers.setWelcomeAd()

      // on ios devices the welcome ad is only displayed partially. That's why this welcome ad container for mobile devices gets selected and scrolled into view
      const element = document.getElementById('ad-sba-slot-mta1');

      if (element) {
        element.scrollIntoView();
      }
    };

    const closeWelcomeAd = () => {
      // window.top.Ads.helpers.closeWelcomeAd();
      // eslint-disable-next-line no-console
      console.info('Close Welcome Ad Container');
    };

    const scrollWelcomeAd = () => {
      // window.top.Ads.helpers.scrollWelcomeAd();
      // eslint-disable-next-line no-console
      console.info('Scroll Welcome Ad Container');
    };

    Object.defineProperty(window, 'Ads', {
      value: {
        helpers: {
          setSideBar,
          resetAll,
          setWelcomeAd,
          closeWelcomeAd,
          scrollWelcomeAd,
        },
      },
      writable: false,
    });
  }
};

/**
 * Used to move down sidebars, so they don't overlap logos on event pages.
 * Not in use anymore because banned RiAd ads on event pages.
 * Keeping it as might be useful someday.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const alignSidebarAdsVerticalPositions = (currentBreakpoint: string) => {
  const topNavBarHeight =
    document.getElementById('navbar')?.getBoundingClientRect()?.height ?? 96;
  const isExtraLargeOrGreater = currentBreakpoint === 'lg';
  const isEventPage = EVENT_PAGE_SLUGS.some((slug) =>
    window.location.pathname.startsWith(`/${slug}`),
  );

  if (!isEventPage && isExtraLargeOrGreater) {
    const leftSidebar =
      (document.getElementsByClassName('side-ad-box-left')[0] as HTMLElement) ??
      null;
    const rightSidebar =
      (document.getElementsByClassName(
        'side-ad-box-right',
      )[0] as HTMLElement) ?? null;

    const wideAdPosition = document
      .getElementById('wideboard-ad-1')
      ?.getBoundingClientRect();

    if (leftSidebar && rightSidebar && wideAdPosition) {
      const marginTop = wideAdPosition.y - topNavBarHeight;

      leftSidebar.style.marginTop = `${marginTop}px`;
      rightSidebar.style.marginTop = `${marginTop}px`;
    }
  }

  // TODO: Could be refactored probably to limit code duplication
  if (isEventPage) {
    const leftSidebar =
      (document.getElementsByClassName('side-ad-box-left')[0] as HTMLElement) ??
      null;
    const rightSidebar =
      (document.getElementsByClassName(
        'side-ad-box-right',
      )[0] as HTMLElement) ?? null;

    if (leftSidebar && rightSidebar) {
      const starNightLeftSideLogoHeight = 130;
      const marginTop = topNavBarHeight + starNightLeftSideLogoHeight;

      leftSidebar.style.marginTop = `${marginTop}px`;
      leftSidebar.style.top = `${marginTop}px`;
      rightSidebar.style.marginTop = `${marginTop}px`;
      rightSidebar.style.top = `${marginTop}px`;
    }
  }
};

export interface AdvertisementProps {
  slot: AdSlot;
  slotId?: string;
}

const Advertisement = forwardRef<HTMLDivElement, AdvertisementProps>(
  ({ slot, slotId }, ref) => {
    if (slot === 'PREROLL_1') {
      return null;
    }

    const config = AdSlotsConfig[slot];

    if (!config) {
      return null;
    }

    if (slot === 'TA_1' || slot === 'MTA_1') {
      return (
        <div
          ref={ref}
          data-slotid={config.container}
          id={slotId || config.container}
          className="ad-container"
        />
      );
    }

    // todo refactor - could merge with below
    if (['SBA_1', 'SBA_2'].includes(slot)) {
      return (
        <div className="relative w-full h-full">
          <div
            data-slotid={config.container}
            id={slotId || config.container}
            className="ad-container relative inset-0 z-10 flex justify-center w-full h-full"
          >
            {/* Advertisement here */}
          </div>

          <div className="ad-ech-placeholder absolute inset-0 flex items-end border rounded-2 border-gray-1 p-4">
            <p className="text-overline text-gray-1">Werbung</p>
          </div>
        </div>
      );
    }

    // todo refactor - could merge with below
    if (['SBA_1', 'SBA_2'].includes(slot)) {
      return (
        <div className="relative w-full h-full">
          <div
            data-slotid={config.container}
            id={slotId || config.container}
            className="ad-container relative inset-0 z-10 flex justify-center w-full h-full"
          >
            {/* Advertisement here */}
          </div>

          <div className="ad-ech-placeholder absolute inset-0 flex items-end border rounded-2 border-gray-1 p-4">
            <p className="text-overline text-gray-1">Werbung</p>
          </div>
        </div>
      );
    }

    return (
      <div
        className="ad-slot relative"
        style={{
          width: config.width,
          ...(config.minHeight
            ? { minHeight: config.minHeight }
            : { height: config.height }),
        }}
      >
        <div
          data-slotid={config.container}
          id={slotId || config.container}
          className={cls(
            config.container,
            'ad-container relative inset-0 z-10 flex justify-center',
          )}
        >
          {/* Advertisement here */}
        </div>

        <div className="ad-ech-placeholder absolute inset-0 flex items-end border rounded-2 border-gray-1 p-4">
          <p className="text-overline text-gray-1">Werbung</p>
        </div>
      </div>
    );
  },
);

export default Advertisement;

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Integrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE || 0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT_PLACEHOLDER,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', /^\/\w/],
    }),
  ],
  ignoreErrors: [
    /Non-Error promise rejection captured.*/,
    /ResizeObserver loop completed with undelivered notifications.*/,
    /ResizeObserver loop limit exceeded.*/,
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

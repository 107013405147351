import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as R from 'ramda';

import { RootState } from '@/store';
import { AdChannel } from '@/types/views/generic';

import HydrateAction from '../hydration';

type Ads = {
  adsEnabled: boolean;
  adChannel: AdChannel | null;
  isCreatorProfilePage: boolean;
  deactivateAdSlots: boolean;
  initializedAdChannel: AdChannel | null;
  adRefreshDelay: number | null;
  prerollRateWeb: number | null;
  isWelcomeAdVisible: boolean;
};

export type SliceState = Ads;

export const initialState: SliceState = {
  adsEnabled: false,
  adChannel: null,
  isCreatorProfilePage: false,
  deactivateAdSlots: true,
  initializedAdChannel: null,
  adRefreshDelay: null,
  prerollRateWeb: null,
  isWelcomeAdVisible: false,
};

const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    setAdsEnabled: (state, action: PayloadAction<Ads['adsEnabled']>) => {
      state.adsEnabled = action.payload;
    },
    setAdChannel: (state, action: PayloadAction<Ads['adChannel']>) => {
      state.adChannel = action.payload;
    },
    setIsCreatorProfilePage: (
      state,
      action: PayloadAction<Ads['isCreatorProfilePage']>,
    ) => {
      state.isCreatorProfilePage = action.payload;
    },
    setDeactivateAdSlots: (
      state,
      action: PayloadAction<Ads['deactivateAdSlots']>,
    ) => {
      state.deactivateAdSlots = action.payload;
    },
    setInitializedAdChannel: (
      state,
      action: PayloadAction<Ads['initializedAdChannel']>,
    ) => {
      state.initializedAdChannel = action.payload;
    },
    setAdRefreshDelay: (
      state,
      action: PayloadAction<Ads['adRefreshDelay']>,
    ) => {
      state.adRefreshDelay = action.payload;
    },
    setPrerollRateWeb: (
      state,
      action: PayloadAction<Ads['prerollRateWeb']>,
    ) => {
      state.prerollRateWeb = action.payload;
    },
    setIsWelcomeAdVisible: (
      state,
      action: PayloadAction<Ads['isWelcomeAdVisible']>,
    ) => {
      state.isWelcomeAdVisible = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(HydrateAction, (state, action) => {
      if (R.equals(state, initialState)) {
        return action.payload.ads;
      }

      if (action.payload.ads.adChannel) {
        state.adChannel = action.payload.ads.adChannel;
      }

      if (action.payload.ads.deactivateAdSlots !== state.deactivateAdSlots) {
        state.deactivateAdSlots = action.payload.ads.deactivateAdSlots;
      }

      return state;
    });
  },
});

export const {
  setAdsEnabled,
  setAdChannel,
  setIsCreatorProfilePage,
  setDeactivateAdSlots,
  setInitializedAdChannel,
  setAdRefreshDelay,
  setPrerollRateWeb,
  setIsWelcomeAdVisible,
} = adsSlice.actions;

export const selectAds = (state: RootState) => state.ads;

export const selectAdsEnabled = (state: RootState) => state.ads.adsEnabled;

export const selectAdsAdChannel = (state: RootState) => state.ads.adChannel;

export const selectDeactivateAdSlots = (state: RootState) =>
  state.ads.deactivateAdSlots;

export const selectInitializedAdChannel = (state: RootState) =>
  state.ads.initializedAdChannel;

export const selectAdRefreshDelay = (state: RootState) =>
  state.ads.adRefreshDelay;

export const selectPrerollRateWeb = (state: RootState) =>
  state.ads.prerollRateWeb;

export const selectIsWelcomeAdVisible = (state: RootState) =>
  state.ads.isWelcomeAdVisible;

export default adsSlice.reducer;

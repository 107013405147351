/* eslint-disable no-param-reassign */
// TODO: do ^ only for "state" parameters
import {
  Action,
  createSelector,
  createSlice,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';
import * as R from 'ramda';

import { CHANNEL_GROUPS_EMPTY, LOCAL_STORAGE_KEYS } from '@/lib/constants';
import getChannel, { getChannelWithGroup } from '@/lib/get-channel';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import { RootState } from '@/store';
import {
  Artist,
  ChannelGroups,
  ChannelGroupType,
  ContentFeedEntrySoundpiece,
  PlaylistItem as PlaylistItemProps,
} from '@/types/views/generic';

import HydrateAction from '../hydration';
import { selectVariablesByKey } from './variables';

export interface Metadata {
  title: string;
  artist: string;
  artists: Artist[] | null;
  duration?: number;
  coverUrl: string;
}

export interface PlayerState {
  libHasLoaded: boolean;
  volume: number;
  isMuted: boolean;
  isPlaying: boolean;
  isPaused: boolean;
  isLoading: boolean;
  metadata: Metadata | null;
  channelGroups: ChannelGroups;
  currentChannelId: string | null;
  currentAudio: ContentFeedEntrySoundpiece | PlaylistItemProps | null;
  isAudioPlaying: boolean;
  isAudioPaused: boolean;
  numberOfChannels: number;
}

export interface BasicMetadata {
  title: string | null;
  artists: string[] | null;
}

export const initialState: PlayerState = {
  libHasLoaded: false,
  volume: 15,
  isMuted: false,
  isPlaying: false,
  isPaused: false,
  isLoading: false,
  metadata: null,
  channelGroups: R.clone(CHANNEL_GROUPS_EMPTY),
  currentChannelId: null,
  numberOfChannels: 3,
  currentAudio: null,
  isAudioPlaying: false,
  isAudioPaused: false,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setHasLibLoaded: (
      state,
      action: PayloadAction<PlayerState['libHasLoaded']>,
    ) => {
      state.libHasLoaded = action.payload;
    },
    setVolumeSimple: (state, action: PayloadAction<PlayerState['volume']>) => {
      state.volume = action.payload;
    },
    setIsMutedSimple: (
      state,
      action: PayloadAction<PlayerState['isMuted']>,
    ) => {
      state.isMuted = action.payload;
    },
    toggleIsMutedSimple: (state) => {
      state.isMuted = !state.isMuted;
    },
    setIsPlaying: (state, action: PayloadAction<PlayerState['isPlaying']>) => {
      state.isPlaying = action.payload;
      state.isPaused = !action.payload;
    },
    setIsLoading: (state, action: PayloadAction<PlayerState['isLoading']>) => {
      state.isLoading = action.payload;
    },
    setMetadata: (state, action: PayloadAction<PlayerState['metadata']>) => {
      state.metadata = action.payload;
    },
    setChannelGroups: (
      state,
      action: PayloadAction<PlayerState['channelGroups']>,
    ) => {
      state.channelGroups = action.payload;
    },
    setCurrentChannelIdSimple: (
      state,
      action: PayloadAction<PlayerState['currentChannelId']>,
    ) => {
      state.currentChannelId = action.payload;
    },
    setCurrentAudio: (
      state,
      action: PayloadAction<PlayerState['currentAudio']>,
    ) => {
      state.currentAudio = action.payload;
    },
    setIsAudioPlaying: (
      state,
      action: PayloadAction<PlayerState['isPlaying']>,
    ) => {
      state.isAudioPlaying = action.payload;
      state.isAudioPaused = !action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(HydrateAction, (state, action) => {
      if (R.equals(state, initialState)) {
        return action.payload.player;
      }

      return state;
    });
  },
});

export const {
  setHasLibLoaded,
  setIsPlaying,
  setIsLoading,
  setMetadata,
  setChannelGroups,
  setCurrentAudio,
  setIsAudioPlaying,
} = playerSlice.actions;

export const setCurrentChannelId =
  (
    channelId: string | null,
  ): ThunkAction<void, unknown, unknown, Action<unknown>> =>
  async (dispatch) => {
    dispatch(playerSlice.actions.setCurrentChannelIdSimple(channelId));

    localStorage.setItem(
      LOCAL_STORAGE_KEYS.currentChannelId,
      JSON.stringify(channelId),
    );

    fetch('/api/switch-channel', {
      method: 'post',
      body: channelId,
    });
  };

export const setVolume =
  (volume: number): ThunkAction<void, unknown, unknown, Action<unknown>> =>
  async (dispatch) => {
    dispatch(playerSlice.actions.setVolumeSimple(volume));

    localStorage.setItem(LOCAL_STORAGE_KEYS.volume, JSON.stringify(volume));

    if (volume === 0) {
      dispatch(playerSlice.actions.setIsMutedSimple(true));
    }
  };

const setIsMutedStateToLocalStorage = (getState: () => RootState) => {
  const isMuted = selectPlayerIsMuted(getState());
  localStorage.setItem(LOCAL_STORAGE_KEYS.isMuted, JSON.stringify(isMuted));
};

export const setIsMuted =
  (mute: boolean): ThunkAction<void, RootState, unknown, Action<unknown>> =>
  async (dispatch, getState) => {
    dispatch(playerSlice.actions.setIsMutedSimple(mute));
    setIsMutedStateToLocalStorage(getState);
  };

export const toggleIsMuted =
  (): ThunkAction<void, RootState, unknown, Action<unknown>> =>
  async (dispatch, getState) => {
    const volume = selectPlayerVolume(getState());
    const isMuted = selectPlayerIsMuted(getState());

    if (volume === 0 && isMuted) {
      dispatch(setVolume(5));
    }

    dispatch(playerSlice.actions.toggleIsMutedSimple());
    setIsMutedStateToLocalStorage(getState);
  };

export const selectPlayer = (state: RootState) => state.player;

export const selectPlayerLibHasLoaded = (state: RootState) =>
  state.player.libHasLoaded;

export const selectPlayerVolume = (state: RootState) => state.player.volume;

export const selectPlayerIsMuted = (state: RootState) => state.player.isMuted;

export const selectPlayerIsPlaying = (state: RootState) =>
  state.player.isPlaying;

export const selectPlayerIsPaused = (state: RootState) => state.player.isPaused;

export const selectPlayerIsLoading = (state: RootState) =>
  state.player.isLoading;

export const selectPlayerMetadata = (state: RootState) => state.player.metadata;

export const selectPlayerChannelGroups = (state: RootState) =>
  state.player.channelGroups;

export const selectPlayerStations = (state: RootState) =>
  state.player.channelGroups.station;

export const selectPlayerCurrentChannel = (state: RootState) =>
  state.player.currentChannelId
    ? getChannel(state.player.channelGroups, state.player.currentChannelId) ??
      null
    : null;

export const selectPlayerCurrentChannelWithGroup = (state: RootState) =>
  state.player.currentChannelId
    ? getChannelWithGroup(
        state.player.channelGroups,
        state.player.currentChannelId,
      ) ?? null
    : null;

export const selectPlayerCurrentAudio = (state: RootState) =>
  state.player.currentAudio;

export const selectAudioPlayerIsPlaying = (state: RootState) =>
  state.player.isAudioPlaying;

export const selectAudioPlayerIsPaused = (state: RootState) =>
  state.player.isAudioPaused;

export const selectPlayerCurrentChannelId = createSelector(
  selectPlayerCurrentChannel,
  (currentChannel) => currentChannel?.id ?? null,
);

export const selectPlayerNumberOfChannels = (state: RootState) => {
  const channelGroupsToCount = R.dissoc(
    ChannelGroupType.External,
    state.player.channelGroups,
  );

  return Object.values(channelGroupsToCount).flat().length;
};

export const selectPlayerStationById = createSelector(
  selectPlayerStations,
  (_: unknown, stationId: string): string => stationId,
  (stations, stationId) => stations.find((station) => station.id === stationId),
);

export const selectPlayerBasicMetadata = createSelector<
  RootState,
  Metadata | null,
  BasicMetadata | null
>(selectPlayerMetadata, (metadata) => {
  if (!metadata) {
    return null;
  }

  return {
    title: metadata.title,
    artists: metadata.artists
      ? metadata.artists.map((a) => a.name)
      : [metadata.artist] ?? null,
  };
});

export const selectPlayerMetadataText = createSelector(
  selectPlayerMetadata,
  selectPlayerCurrentChannel,
  selectPlayerNumberOfChannels,
  selectVariablesByKey,
  (metadata, currentChannel, numberOfChannels, variables) => ({
    primary:
      metadata?.title ??
      getVariablePlainText(variables['player-idle-text-primary'])?.replace(
        '$1',
        currentChannel?.name ?? '',
      ) ??
      '',
    secondary:
      metadata?.artist ??
      getVariablePlainText(variables['player-idle-text-secondary'])?.replace(
        '$1',
        numberOfChannels.toString(),
      ) ??
      '',
  }),
);

export const selectAudioPlayerMetadataText = createSelector(
  selectPlayerCurrentAudio,
  (currentAudio) => {
    if (!currentAudio) return undefined;

    const description =
      'description' in currentAudio && currentAudio.description;
    const artist = 'artist' in currentAudio && currentAudio.artist;

    return {
      primary: currentAudio.title,
      secondary: description || artist,
    };
  },
);

export default playerSlice.reducer;

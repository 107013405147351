import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';

import adsReducer from './slices/ads';
import globalReducer from './slices/global';
import playerReducer from './slices/player';
import userReducer from './slices/user';
import variablesReducer from './slices/variables';
import videoReducer from './slices/video';

const combinedReducer = combineReducers({
  ads: adsReducer,
  global: globalReducer,
  variables: variablesReducer,
  player: playerReducer,
  user: userReducer,
  video: videoReducer,
});

const initStore = () =>
  configureStore({
    reducer: combinedReducer,
    devTools: process.env.NODE_ENV !== 'production',
  });

export type AppStore = ReturnType<typeof initStore>;

export type StoreDispatch = AppStore['dispatch'];

export const useStoreDispatch = () => useDispatch<StoreDispatch>();

export type RootState = ReturnType<AppStore['getState']>;

export const wrapper = createWrapper(initStore);

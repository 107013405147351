import { ChannelGroups, ChannelGroupType } from '@/types/views/generic';

export const getChannelWithGroup = (
  channelGroups: ChannelGroups,
  id: string,
) => {
  for (const [group, channels] of Object.entries(channelGroups)) {
    const channel = channels.find((channelItem) => channelItem.id === id);

    if (channel) {
      return {
        channel,
        group: group as ChannelGroupType,
      };
    }
  }

  return null;
};

const getChannel = (channelGroups: ChannelGroups, id: string) =>
  Object.values(channelGroups)
    .flat()
    .find((channel) => channel.id === id);

export default getChannel;

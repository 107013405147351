const pipe = require('ramda/src/pipe');
const map = require('ramda/src/map');
const unnest = require('ramda/src/unnest');
const fromPairs = require('ramda/src/fromPairs');
const range = require('ramda/src/range');

/**
 * Example usage:
 *   generateFractionsObject([2, 3])
 * Will give:
 *   { '1/2': '50.000%', '1/3': '33.333%', '2/3': '66.667%' }
 * @param {Array<number>} denominators
 * @return {Object.<string, string>}
 */
const generateFractionsObject = pipe(
  map((denominator) =>
    map((numerator) => [
      `${numerator}/${denominator}`,
      `${((numerator / denominator) * 100).toPrecision(7)}%`,
    ])(range(1, denominator)),
  ),
  unnest,
  fromPairs,
);

module.exports = generateFractionsObject;

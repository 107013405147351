import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as R from 'ramda';

import { RootState } from '@/store';
import { Variable } from '@/types/views/generic';

import HydrateAction from '../hydration';

export type VariablesState = Variable[];

export const initialState: VariablesState = [];

const variablesSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {
    setVariables: (_, action: PayloadAction<Variable[]>) => action.payload,
  },
  extraReducers(builder) {
    builder.addCase(HydrateAction, (state, action) => {
      if (R.equals(state, initialState)) {
        return action.payload.variables;
      }

      return state;
    });
  },
});

export const { setVariables } = variablesSlice.actions;

export const selectVariables = (state: RootState) => state.variables;

export const selectVariablesByKey = createSelector(
  selectVariables,
  (variables) =>
    variables.reduce(
      (prev: Record<string, Variable['text']>, { slug, text }) => {
        // eslint-disable-next-line no-param-reassign
        prev[slug] = text;

        return prev;
      },
      {},
    ),
);

export default variablesSlice.reducer;

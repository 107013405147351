import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * Store path values in session storage
 */
const useInitPrevPath = () => {
  const { asPath } = useRouter();

  useEffect(() => {
    if (!sessionStorage) return;

    // Set the previous path as the value of the current path.
    const prevPath = sessionStorage.getItem('currentPath');
    const currentPath = asPath;

    if (prevPath?.length && prevPath !== currentPath) {
      sessionStorage.setItem('prevPath', prevPath);
    }

    // Set the current path value by looking at the browser's location object.
    sessionStorage.setItem('currentPath', currentPath);
  }, [asPath]);
};

export const usePrevPath = () => {
  const [prevPath, setPrevPath] = useState('/');

  useEffect(() => {
    const newPrevPath = sessionStorage?.getItem('prevPath');

    if (newPrevPath && newPrevPath !== prevPath) {
      setPrevPath(newPrevPath);
    }
  }, [prevPath, setPrevPath]);

  return prevPath;
};

export default useInitPrevPath;

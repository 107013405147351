import { Document } from '@contentful/rich-text-types';

import { AudioTimeUpdateEvent } from '@/lib/hooks/use-audio-player';
import { CustomRichTextDocument } from '@/middleware/mappers/rich-text-body/rich-text-body';

import { PrizeItems, PrizeStatus, TagType } from '../apis';
import {
  ContentFeedEntryCmsDisplayStyle,
  ContentFeedEntryCmsEntryType,
  LinkActionTypeCms,
  PageThemeCms,
} from '../cms';
import { BoxSectionEntry, Section, SectionEntryLayout } from './sections';

export type EntryComponentProps<T> = Omit<T, 'id' | 'componentType'>;

export type StaticEntryComponentProps<T> = Omit<
  T,
  'id' | 'componentType' | 'slug' | 'title' | 'showTitle'
>;

export type PageTheme =
  | 'light'
  | 'dark'
  | 'eone'
  | 'eair'
  | 'esn'
  | 'eds'
  | 'els'
  | 'game'
  | 'legal'
  | 'music-award';

export type EventPageSlug =
  | 'air'
  | 'star-night'
  | 'die-sprechstunde-live'
  | 'live-session';

export interface ComponentEntry {
  id: string;
  componentType: string;
  hasInvertedThemeColor?: boolean;
  deactivateAdSlots?: boolean;
}

export interface PageEntry {
  title: string;
  theme?: PageTheme;
  seo?: SeoEntry;
  sections: Section[];
  backgroundImage?: BackgroundImage;
  breadcrumbs?: BreadcrumbsEntry;
  isCreatorProfilePage?: boolean | null;
  deactivateAdSlots?: boolean;
  isIndexed?: boolean;
  hasInvertedThemeColor?: boolean;
  subNavigation?: SubNavigationEntry;
}

export interface BackgroundImage {
  desktop: ImageAsset | null;
  mobile: ImageAsset | null;
}

export interface SeoEntry {
  title: string;
  description: string;
  image?:
    | ImageAsset
    | {
        src: string;
      };
  url: string;
}

export type AssetContentType = 'image' | 'video';

interface AssetBase {
  id: string;
  contentType: AssetContentType;
}

export interface ImageAsset extends AssetBase {
  src: string;
  width: number;
  height: number;
  description?: string;
  contentType: 'image';
}

export interface VideoAsset extends AssetBase {
  src: string;
  description?: string;
  contentType: 'video';
}

export type MediaAsset = VideoAsset | ImageAsset;

export interface Link {
  id: string;
  href: string;
  as?: string;
  isExternal: boolean;
  layout?: SectionEntryLayout;
  download?: boolean;
}

export interface TextLink extends Link {
  text: string;
}

export interface NavItemEntry {
  link: TextLink;
  action?: LinkActionTypeCms;
}

export interface NavItemEntryWithIcon extends NavItemEntry {
  icon: ImageAsset;
}

export interface CardNavItemEntry extends NavItemEntry {
  cardImage: ImageAsset;
}

export enum NavigationType {
  // nav
  MainNavigation = 'main-navigation',
  FastIconNavigation = 'fast-icon-navigation',
  SmallLabelNavigation = 'label-navigation',
  CardNavigation = 'card-navigation',
  // footer
  RadiosNavigation = 'radios-navigation',
  AppsNavigation = 'apps-navigation',
  LabelNavigation = 'label-navigation',
  MediaNavigation = 'media-navigation',
  SocialNavigation = 'social-navigation',
  // events
  EnergyAirNavigation = 'energy-air-navigation',
  EnergyStarNightNavigation = 'energy-star-night-navigation',
  EnergyDieSprechstundeNavigation = 'die-sprechstunde-event-navigation',
  EnergyLiveSessionNavigation = 'live-session',
  // account
  AccountNavigation = 'account-navigation',
}

export interface NavigationEntry {
  // nav
  mainNavItems: NavItemEntry[];
  smallLabelNavItems: NavItemEntry[];
  fastIconNavItems: NavItemEntryWithIcon[];
  cardNavItems: CardNavItemEntry[];
  // footer
  radiosNavItems: NavItemEntry[];
  appsNavItems: NavItemEntry[];
  labelNavItems: NavItemEntry[];
  mediaNavItems: NavItemEntry[];
  socialNavItems: NavItemEntryWithIcon[];
  // events
  eventNavItems?: NavItemEntry[];
}

export interface SubNavigationEntry {
  subNavItems: NavItemEntry[];
  parentItem?: NavItemEntry;
  currentAsPath?: string;
}

export interface Variable {
  title: string;
  slug: string;
  text: Document;
}

export type ButtonSize = 'S' | 'M' | 'L' | 'XL';

export type ButtonColor = 'red' | 'white' | 'gray' | 'black';

export type ButtonColorEAIR = 'white' | 'blue' | 'coral';

export type ButtonColorESN = 'mint' | 'blue';

export type ButtonColorEDS = 'pink' | 'black';

export type ButtonColorELS = 'blue' | 'light-blue' | 'grey-blue';

export type ButtonColorGame = 'black' | 'white';

export type ButtonVariant = 'default' | 'ghost';

export type ButtonVariantEAIR = 'eair';

export type ButtonVariantESN = 'esn';

export type ButtonVariantEDS = 'eds';

export type ButtonVariantELS = 'els';

export type ButtonVariantGame = 'game';

export interface SliderItemEntry {
  title: string;
  description?: CustomRichTextDocument;
  background: ImageAsset;
  backgroundMobile: ImageAsset;
  link?: TextLink | LotteryFormButton;
}

export interface ContentFeedLayout {
  mobile: ContentFeedEntryCmsDisplayStyle;
  desktop: ContentFeedEntryCmsDisplayStyle;
}

export enum TeaserGroup {
  Default,
  PodcastsCreatorsChannels,
  Memes,
  Categories,
  Videos,
  Stations,
  Prizes,
  Contact,
}

export interface TagBase {
  name: string;
  cmsEntryId: string;
}

export interface Tag extends TagBase {
  image: ImageAsset;
  type: TagType;
  link: Link;
}

export type TagChipType = Omit<Tag, 'type' | 'cmsEntryId'>;

export interface ImageWithText extends ComponentEntry {
  title?: string;
  link?: Link;
  image: ImageAsset;
  text?: Document;
}

export interface ContentFeedEntry extends ComponentEntry {
  componentType: ContentFeedEntryEntryType;
}

export type ContentFeedEntryEntryType =
  | ContentFeedEntryCmsEntryType
  | 'creator'
  | 'soundpiece'
  | 'mediumRectangleAd'
  | 'mobileMediumRectangleAd'
  | 'prize'
  | 'contactItem';

export type TaggingToolContentType =
  | 'meme'
  | 'video'
  | 'soundpiece'
  | 'format'
  | 'show';

export interface ContentFeedEntryVideo extends ContentFeedEntry {
  taggingToolId: string;
  componentType: 'video';
  title: string;
  description: string;
  videoPreviewImage?: string;
  videoPreviewVideo?: string;
  videoUrl: string;
  teaser: EntryComponentProps<BoxSectionEntry>[];
  contentCategories: string[];
  tags: Tag[];
  link: Link;
  publishDate: string | null;
  appearInWatchExperience: boolean;
  isSponsored: boolean;
}

export interface ContentFeedEntryMeme extends ContentFeedEntry {
  componentType: 'meme';
  image: string;
  title?: string;
  description?: string;
  contentCategories: string[];
  publishDate: string | null;
  teaser: EntryComponentProps<BoxSectionEntry>[];
  link: Link;
  tags: Tag[];
  appearInWatchExperience: boolean;
}

export interface DeliveryOption {
  id: string | null;
  name: string;
  type: string;
}

export interface PrizeEntry {
  lotteryId: number | null;
  id: string;
  type: string;
  status: PrizeStatus;
  name: string;
  description: string;
  expiresAt: string;
  deliveryOptions: DeliveryOption[];
  tickets: PrizeItems[];
  amount: number;
  categories?: string;
  isUpgrade?: boolean;
  ticketUrl?: string;
  image?: string | null;
  imageMobile?: string | null;
  shopLink?: string;
  details?: string;
  modus?: string;
}

export interface ContentFeedEntryPrize extends ContentFeedEntry {
  componentType: 'prize';
  title: string;
  description: string;
  amount: number;
  status: string;
  categories: string;
  ticketUrl?: string;
  image?: string;
  imageMobile?: string;
  details?: string;
  modus?: string;
  tickets?: PrizeItems[];
  type: string;
}

export interface ContentFeedEntryContactItem extends ContentFeedEntry {
  componentType: 'contactItem';
  text: string;
  email: string;
  icon: string;
  iconSelected?: string;
}

export interface ContactItemEntry {
  text: string;
  email: string;
  icon: string;
  iconSelected?: string;
}

export interface ContentFeedEntryCategory extends ContentFeedEntry {
  componentType: 'category';
  title: string;
  image: ImageAsset;
  link: Link;
}

export interface ContentFeedEntryStation extends ContentFeedEntry {
  componentType: 'station';
  title: string;
  image: ImageAsset;
  stationId: string;
  link: Link;
}

export interface ContentFeedEntrySoundpiece extends ContentFeedEntry {
  componentType: 'soundpiece';
  title: string;
  description: string;
  audioUrl: string;
  coverUrl: string;
  tags: Tag[];
  publishDate: string | null;
  duration: number;
}

export interface SoundpieceProps extends ContentFeedEntrySoundpiece {
  onPlay: () => void;
  onPause: () => void;
  isActive: boolean;
  audioTimeUpdateEvent?: AudioTimeUpdateEvent;
  duration: number;
}

export interface ShowFormatProfileTeaser {
  title: string;
  image: ImageAsset;
  link: Link;
}

export interface ContentFeedEntryShow
  extends ContentFeedEntry,
    ShowFormatProfileTeaser {
  componentType: 'show';
  title: string;
}

export interface ContentFeedEntryFormat
  extends ContentFeedEntry,
    ShowFormatProfileTeaser {
  componentType: 'format';
}

export interface ContentFeedEntryProfile
  extends ContentFeedEntry,
    ShowFormatProfileTeaser {
  componentType: 'profile';
}

export interface ContentFeedEntryCreator extends ContentFeedEntry {
  componentType: 'creator';
  image: ImageAsset;
  tag: Tag;
  link: Link;
}

export interface ChannelPodcastTeaser {
  title: string;
  description?: string;
  imageUrl?: string;
  link: Link;
}

export interface ContentFeedEntryChannel
  extends ContentFeedEntry,
    ChannelPodcastTeaser {
  componentType: 'channel';
}

export interface ContentFeedEntryPodcast
  extends ContentFeedEntry,
    ChannelPodcastTeaser {
  componentType: 'podcast';
}

export interface Channel {
  slug?: string;
  id: string;
  name: string;
  shortName: string;
  streamUrl: string;
  streamUrlLow: string;
  logoUrl?: string;
  shortDescription?: string;
  seoTitle?: string;
  seoDescription?: string;
  description?: string;
  bgDesktop?: string;
  bgMobile?: string;
  frequency?: string;
}

export enum ChannelGroupType {
  Station = 'station',
  Music = 'music',
  External = 'external',
}

export type ChannelGroups = Record<ChannelGroupType, Channel[]>;

export interface FooterThemeNav {
  title: string;
  list: NavItemEntry[];
  orderIndex?: number;
  className?: string;
}

export interface ThemeSettings {
  title: string;
  urlSlug: string;
  eventNav: NavItemEntry[];
  logo: ImageAsset;
  logoMobile?: ImageAsset;
  locationTimeImage?: ImageAsset;
  energyLogo?: ImageAsset | null;
  notFoundPageBg?: ImageAsset | null;
  errorPageBackground?: ImageAsset | null;
  footerNavigation: FooterThemeNav[];
  socialNavigation?: {
    title?: string;
    navItems: NavItemEntryWithIcon[];
  };
}

export interface PageProps {
  isPreviewModeEnabled: boolean;
  page: PageEntry;
  navigation: NavigationEntry;
  theme?: PageTheme;
  themeSettings?: ThemeSettings;
  seo?: SeoEntry;
}

export interface ArticleTeaserEntry {
  id: string;
  link: Link;
  title: string;
  image: ImageAsset;
  creators?: string[];
  categories?: string[];
  isSponsoredContent: boolean;
}

export type ExternalVideo = { url: string };

export type InternalVideo = {
  taggingToolId: string;
  title: string;
  url: string;
  posterUrl?: string;
  description?: string;
  tags?: Tag[];
  publishDate?: string;
  contentCategories?: string[];
  isSponsored?: boolean;
  removePreroll?: boolean;
};

export interface Meme {
  componentType: 'meme';
  id: string;
  image: string;
  description?: string;
  tags: Tag[];
  publishDate?: string;
  contentCategories: string[];
}

export interface Soundpiece {
  componentType: 'soundpiece';
  id: string;
  image: string;
  description?: string;
  tags: Tag[];
  publishDate?: string;
  duration: number;
  contentCategories: string[];
}

export interface WatchTeaserEntry {
  teaser: Omit<BoxSectionEntry, 'id' | 'componentType'>[];
}

export interface WatchDetailVideosEntry extends WatchTeaserEntry {
  video: InternalVideo;
}

export interface WatchDetailMemesEntry extends WatchTeaserEntry {
  meme: Meme;
}

// TODO. Will be changed later to contain data for children.
interface Breadcrumb {
  text: string;
  slug: string;
}

export interface BreadcrumbsEntry {
  hideOnMobile: boolean;
  showBorder?: boolean;
  breadcrumbItems?: Breadcrumb[];
}

export enum NewsletterFormStep {
  First = 1,
  Second,
  Third,
}

export interface ToggleData {
  href: string;
  text: string;
  imageSrc?: string;
}

export interface Artist {
  id: number;
  name: string;
}

export enum ContentEntryType {
  Video = 'video',
  Meme = 'meme',
  Category = 'category',
  Station = 'station',
  Channel = 'channel',
  Podcast = 'podcast',
  Show = 'show',
  Format = 'format',
  Profile = 'profile',
}

export interface ShowEntry {
  title: string;
  from: number;
  to: number;
  showCoverUrl: string | null;
  moderatorTags: Tag[];
}

export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

export type OrderType = 'asc' | 'desc';

export interface FaqItemEntry {
  id: string;
  slug: string;
  title: string;
  text: CustomRichTextDocument;
}

export interface RelatedArticleEntry {
  title: string;
  slug: string;
  link: Link;
  image: ImageAsset;
  contentCategories?: string[];
  profiles?: TagChipType[];
  updatedAt: string;
  theme?: PageThemeCms;
}

export interface TeamMember {
  name: string;
  image: string;
  job: string;
  department: string;
  link?: Link;
}

export interface Filter {
  id: string;
  bmId?: string;
  text: string;
}

export type PlaylistItem = {
  imageUrl: string;
  playFrom: string;
  audioUrl?: string;
  artist?: string;
  title?: string;
};

export interface LotteryV2FieldOption {
  title: string;
  value: string;
}

export interface LotteryV2FieldSettings {
  options: LotteryV2FieldOption[];
}

export interface LotteryV2Field {
  id: number;
  name: string;
  displayName: string;
  type: string | null; // TODO (lottery): probably could be narrowed to 'textarea' | 'select'
  settings: LotteryV2FieldSettings;
  potId: number;
}

export interface LotteryV2Pot {
  potId: number;
  lotteryId: number;
  name: string;
  type: string | null;
  multipleParticipations: boolean;
  customFieldPositionTop: boolean;
  customFields: LotteryV2Field[];
}

export interface LotteryV2 {
  id: number;
  title: string;
  name: string;
  active: 0 | 1;
  startedAt: string; // format YYYY-MM-DD HH:MM:SS
  endedAt: string;
  defaultPot: LotteryV2Pot;
}

export interface LotteryFormTeaser {
  teaserTitle: string;
  lottery: LotteryV2;
  layout: SectionEntryLayout;
  text?: Document;
  tag?: string;
  ctaText?: string;
  deadline?: string;
}

export enum LotteryState {
  Form = 'form',
  Success = 'success',
  Error = 'error',
  ErrorAlreadyParticipated = 'error-alreadyParticipated',
}

export interface LotteryFormButton {
  componentType: 'lotteryFormButton';
  lotteryTitle: string;
  ctaText: string;
  lotteryPot: LotteryV2Pot;
  initialLotteryState?: LotteryState;
}

export interface IconLinkEntry extends ComponentEntry {
  componentType: 'iconLink';
  title: string;
  icon: ImageAsset;
  link: Link;
}

export interface MusicAwardActEntry extends ComponentEntry {
  title: string;
  image: ImageAsset;
  lotteryId: number;
  lotteryPool: number;
  year: string;
  isWinner?: boolean;
  url?: string;
}

export type AdChannel =
  | 'Home'
  | 'Watch'
  | 'OnAir'
  | 'Channels'
  | 'Profiles'
  | 'Events'
  | 'RoS'
  | 'Air'
  | 'StarNight'
  | 'Game'
  | 'DieSprechstunde';

export type AdSlot =
  | 'SBA_1'
  | 'SBA_2'
  | 'TA_1'
  | 'MTA_1'
  | 'WB_1'
  | 'WB_2-MR'
  | 'WB_3'
  | 'MR_1'
  | 'MR_2'
  | 'MR_3'
  | 'MMR_1'
  | 'MMR_2'
  | 'MHPA_2'
  | 'MHPA_3'
  | 'PREROLL_1';

export type AdPlacementsProps = Record<AdChannel, Record<string, AdSlot[]>>;

export type TagsByCmsId = Record<string, Tag>;

export interface ContentCategory {
  title: string;
  slug: string;
}

export type ContentCategoriesByCmsId = Record<string, ContentCategory>;

export type ItemsCollection<T = unknown> = {
  items: T[];
  totalCount: number;
};

export type ContentFeedEntriesCollection = ItemsCollection<ContentFeedEntry>;

export enum LinkActionType {
  OpenNewsletter = 'openNewsletter',
  EditProfile = 'editProfile',
}

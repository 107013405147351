import { useSelector } from 'react-redux';

import { selectGlobalPageTheme } from '@/store/slices/global';

import { EVENT_THEMES } from '../constants';

const usePageTheme = () => {
  const pageTheme = useSelector(selectGlobalPageTheme);
  const isEventTheme = !!(pageTheme && EVENT_THEMES.includes(pageTheme));
  const isGameTheme = pageTheme === 'game';

  return { pageTheme, isEventTheme, isGameTheme };
};

export default usePageTheme;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as R from 'ramda';

import { RootState } from '@/store';
import { PageTheme } from '@/types/views/generic';

import HydrateAction from '../hydration';

type Global = {
  pageTheme: PageTheme | null;
  JWPlayerLibHasLoaded: boolean;
  isNewsletterOpen: boolean;
  comscoreCsFpid: string | null;
  isChannelSwitcherOpen: boolean;
};

export type SliceState = Global;

export const initialState: SliceState = {
  pageTheme: null,
  JWPlayerLibHasLoaded: false,
  isNewsletterOpen: false,
  comscoreCsFpid: null,
  isChannelSwitcherOpen: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setPageTheme: (state, action: PayloadAction<Global['pageTheme']>) => {
      state.pageTheme = action.payload;
    },
    setJWPlayerLibHasLoaded: (
      state,
      action: PayloadAction<Global['JWPlayerLibHasLoaded']>,
    ) => {
      state.JWPlayerLibHasLoaded = action.payload;
    },
    setIsNewsletterOpen: (
      state,
      action: PayloadAction<Global['isNewsletterOpen']>,
    ) => {
      state.isNewsletterOpen = action.payload;
    },
    setComscoreCsFpid: (
      state,
      action: PayloadAction<Global['comscoreCsFpid']>,
    ) => {
      state.comscoreCsFpid = action.payload;
    },
    toggleIsChannelSwitcherOpen: (state) => {
      state.isChannelSwitcherOpen = !state.isChannelSwitcherOpen;
    },
  },
  extraReducers(builder) {
    builder.addCase(HydrateAction, (state, action) => {
      if (R.equals(state, initialState)) {
        return action.payload.global;
      }

      if (action.payload.global.pageTheme) {
        state.pageTheme = action.payload.global.pageTheme;
      }

      return state;
    });
  },
});

export const {
  setPageTheme,
  setJWPlayerLibHasLoaded,
  setIsNewsletterOpen,
  setComscoreCsFpid,
  toggleIsChannelSwitcherOpen,
} = globalSlice.actions;

export const selectGlobal = (state: RootState) => state.global;

export const selectGlobalPageTheme = (state: RootState) =>
  state.global.pageTheme;

export const selectJWPlayerLibHasLoaded = (state: RootState) =>
  state.global.JWPlayerLibHasLoaded;

export const selectIsNewsletterOpen = (state: RootState) =>
  state.global.isNewsletterOpen;

export const selectComscoreCsFpid = (state: RootState) =>
  state.global.comscoreCsFpid;

export const selectIsChannelSwitcherOpen = (state: RootState) =>
  state.global.isChannelSwitcherOpen;

export default globalSlice.reducer;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserProfileResponse } from '@/api/types';
import { RootState } from '@/store';

export type UserState = {
  profile: UserProfileResponse | null;
  comscoreFirstPartyIdHash: string | null;
  isLoading: boolean;
  isDataInitialized: boolean;
};

export const initialState: UserState = {
  profile: null,
  isLoading: true,
  isDataInitialized: false,
  comscoreFirstPartyIdHash: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSessionData: (
      state,
      action: PayloadAction<{
        profile: UserState['profile'];
        comscoreFirstPartyIdHash: UserState['comscoreFirstPartyIdHash'];
      }>,
    ) => {
      // TODO: Refactor
      state.profile = action.payload.profile;

      state.comscoreFirstPartyIdHash = action.payload.comscoreFirstPartyIdHash;
      state.isLoading = false;
      state.isDataInitialized = true;
    },
    getUserInit: (state) => {
      state.isLoading = true;
    },
  },
});

export const { setSessionData, getUserInit } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export const selectIsUserLoggedIn = (state: RootState) => !!state.user.profile;

export const selectIsUserLoading = (state: RootState) => state.user.isLoading;

export const selectIsUserDataInitialized = (state: RootState) =>
  state.user.isDataInitialized;

export const selectUserProfile = (state: RootState) => state.user.profile;

export const selectCurrentUserInitials = createSelector(
  selectUserProfile,
  (profile) =>
    `${profile?.firstName.charAt(0) || ''}${profile?.lastName.charAt(0) || ''}`,
);

export default userSlice.reducer;

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearAds,
  initAds,
  mountAdsHelperFunctionsOnWindow,
} from '@/components/advertisement';
import { selectAds, setInitializedAdChannel } from '@/store/slices/ads';
import { selectIsUserLoading, selectIsUserLoggedIn } from '@/store/slices/user';

import useBreakpoint from './use-breakpoint';

const useInitAds = () => {
  const { asPath } = useRouter();
  const adsConfig = useSelector(selectAds);
  const isUserLoading = useSelector(selectIsUserLoading);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const { currentBreakpoint, isMobile } = useBreakpoint();
  const dispatch = useDispatch();

  useEffect(() => {
    mountAdsHelperFunctionsOnWindow();

    if (isUserLoading) {
      // we only want to register once per page load
      // so we need to wait for user to be loaded
      return;
    }

    if (
      !adsConfig.adsEnabled ||
      adsConfig?.deactivateAdSlots ||
      !adsConfig?.adChannel
    ) {
      return;
    }

    const { adChannel } = adsConfig;

    initAds(
      adChannel,
      isMobile,
      currentBreakpoint,
      adsConfig.isCreatorProfilePage,
      isUserLoggedIn,
      () => {
        dispatch(setInitializedAdChannel(adsConfig.adChannel));
      },
    );

    // eslint-disable-next-line consistent-return
    return () => {
      if (adsConfig.adsEnabled) {
        // eslint-disable-next-line no-console
        console.info('clearing ads');
        clearAds();
      }
    };

    // NOTE: Should be run just once on each route change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, isUserLoggedIn, isUserLoading]);
};

export default useInitAds;

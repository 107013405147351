const fetchWithCatch = async <T>(fetchFn: () => Promise<Response>) => {
  let response: Response | null = null;

  try {
    response = await fetchFn();

    const isJsonContentType = !!response.headers
      .get('Content-Type')
      ?.includes('application/json');

    const data: T = isJsonContentType
      ? await response.json()
      : await response.text();

    if (!response.ok) {
      return {
        data,
        response,
        error: new Error(
          `Status: ${response.status} - ${response.statusText}` +
            `\nURL: ${response.url}` +
            `\nMessage:\n${JSON.stringify(data)}`,
        ),
      };
    }

    return { response, data };
  } catch (error) {
    console.error(error);

    return {
      data: null,
      response,
      error,
    };
  }
};

export default fetchWithCatch;

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import '@/styles/globals.css';

import { AppProps } from 'next/app';
import Head from 'next/head';

import { AVAILABLE_PAGE_THEMES } from '@/lib/constants';
import useHandleURLHashAction from '@/lib/hooks/use-handle-url-hash-action';
import useInitAds from '@/lib/hooks/use-init-ads';
import useInitPrevPath from '@/lib/hooks/use-init-prev-path';
import useInitSession from '@/lib/hooks/use-init-session';
import useInitStore from '@/lib/hooks/use-init-store';
import usePageTheme from '@/lib/hooks/use-page-theme';
import useProgressBar from '@/lib/hooks/use-progress-bar';
import useSmoothScroll from '@/lib/hooks/use-smooth-scroll';
import useTrackComscore from '@/lib/hooks/use-track-comscore';
import { useIsomorphicLayoutEffect } from '@/lib/utils';
import { wrapper } from '@/store';
import { PageTheme } from '@/types/views/generic';

const directories: Partial<Record<PageTheme, string>> = {
  esn: 'esn',
  eair: 'eair',
};

function MyApp({ Component, pageProps, router }: AppProps) {
  useInitSession();
  useInitStore();
  useProgressBar();
  useTrackComscore();
  useHandleURLHashAction();
  useSmoothScroll();
  useInitAds();
  useInitPrevPath();

  const { pageTheme, isEventTheme } = usePageTheme();
  const isEsnTheme = isEventTheme && pageTheme === 'esn';
  const imagesDir = (pageTheme && directories[pageTheme]) || 'ech';
  const directory = `/ech-new/images/${imagesDir}`;

  useIsomorphicLayoutEffect(() => {
    if (pageTheme) {
      AVAILABLE_PAGE_THEMES.forEach((theme) => {
        document.documentElement.classList.remove(theme);
      });

      document.documentElement.classList.add(pageTheme);
    }
  }, [pageTheme]);

  return (
    <>
      <Head>
        <link rel="icon" href={`${directory}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${directory}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${directory}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${directory}/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`/site${
            pageTheme && directories[pageTheme]
              ? `-${directories[pageTheme]}`
              : ''
          }.webmanifest`}
        />
        <meta
          name="msapplication-TileColor"
          content={isEsnTheme ? '#2b5797' : '#9f00a7'}
        />
        <meta name="theme-color" content="#ffffff" />

        <title>Energy.ch</title>
      </Head>

      <Component {...pageProps} />
    </>
  );
}

export default wrapper.withRedux(MyApp);
